import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { ApolloProvider } from '@apollo/react-hooks'
import { setContext } from '@apollo/client/link/context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { HttpLink } from 'apollo-link-http'
import { ApolloClient } from 'apollo-client'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth'
import { MessageProvider } from '@trileuco/triskel-react-ui/components/i18n'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV || 'development',
  integrations: [new Integrations.BrowserTracing()],

  tracesSampleRate: 0.5
})

const TenantContext = createContext({})

export const useTenantConfig = () => {
  return useContext(TenantContext)
}

const Provider = ({ children, tenantConfig }) => {
  const authContext = useAuth()
  const onErrorLink = onError(({ networkError }) => {
    if (networkError) console.log(`[Network error]: ${networkError}`)
  })
  const authLink = setContext((_, { headers }) => {
    const accessHeader =
      authContext.keycloak && authContext.keycloak.token
        ? {
            'access-token': authContext.keycloak.token
          }
        : {}
    return {
      headers: {
        ...headers,
        ...accessHeader
      }
    }
  })
  const link = ApolloLink.from([
    onErrorLink,
    authLink,
    new HttpLink({
      uri: tenantConfig.api.baseUrl
    })
  ])
  const client = new ApolloClient({
    link,
    cache: new InMemoryCache()
  })
  return (
    <TenantContext.Provider value={tenantConfig}>
      <ApolloProvider client={client}>
        <MessageProvider scope="equipzilla">{children}</MessageProvider>
      </ApolloProvider>
    </TenantContext.Provider>
  )
}

Provider.propTypes = {
  children: PropTypes.node,
  tenantConfig: PropTypes.object
}

Provider.displayName = 'Provider'

export default Provider
