import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@trileuco/triskel-react-ui/components/ui'

import FeatheryForm from './FeatheryForm'

const FeatheryModalCloseButton = ({ close }) => (
  <button className="FeatheryModalCloseButton" onClick={close} />
)

FeatheryModalCloseButton.propTypes = {
  close: PropTypes.func
}

const FeatheryRawModal = ({
  formId,
  hiddenFields,
  title,
  onSubmit,
  ...props
}) => {
  return (
    <Modal
      className="FeatheryModal"
      name="FeatheryRawModal"
      header={
        <>
          <p>{title}</p>
          <FeatheryModalCloseButton close={props.onClose} />
        </>
      }
      body={
        <FeatheryForm
          formId={formId}
          hiddenFields={hiddenFields}
          onSubmit={onSubmit}
        />
      }
      {...props}
    />
  )
}

FeatheryRawModal.propTypes = {
  formId: PropTypes.string.isRequired,
  hiddenFields: PropTypes.object,
  title: PropTypes.string,
  initialOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
}

FeatheryRawModal.displayName = 'FeatheryRawModal'

export default FeatheryRawModal
