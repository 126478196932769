import queryString from 'query-string'

const fetchTenantConfig = () =>
  fetch(
    `${
      process.env.REACT_APP_API_BASE_URL
    }/tenants/config?${queryString.stringify({
      host: document.location.hostname,
      path: document.location.pathname
    })}`
  ).then((response) => response.json())

export const getTenantConfig = () =>
  new Promise((resolve) => {
    if (window.tenantConfig) {
      resolve(window.tenantConfig)
    } else {
      fetchTenantConfig().then((config) => {
        if (config.errorCode) {
          const errorMessage =
            'There were some error loading tenant config: ' +
            '\nHost: ' +
            document.location.hostname +
            '\nPath: ' +
            document.location.pathname +
            '\nError: ' +
            JSON.stringify(config)
          console.log(errorMessage)
          alert(errorMessage)
          return
        }
        resolve(config)
      })
    }
  })

export default getTenantConfig
