import React, { useCallback, useState } from 'react'
import _ from 'lodash'
import { useLocation } from '@trileuco/triskel-react-ui/components/router'
import { useAnalytics } from '@trileuco/triskel-react-ui/modules/analytics'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth'
import { useNotifications } from '@trileuco/triskel-react-ui/components/ui/notifications'
import { useErrorNotification } from 'modules/notifications'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { Modal } from '@trileuco/triskel-react-ui/components/ui'
import { useSignIn } from 'modules/graphql/hooks/auth'
import Block from 'components/layout/Block'
import { FeatheryForm } from 'modules/feathery'

import SignInForm from './SignInForm'

function scrollToModalTop() {
  var element = document.querySelector('.AuthWrapper')
  element.scrollIntoView({ behavior: 'smooth', block: 'start' })
}

const AuthModal = (props) => {
  const authContext = useAuth()
  const { initializing, recoveryUrl } = authContext
  const [mode, setMode] = useState('signin')
  const { msg } = useIntl({
    scope: 'equipzilla.signModal',
    ignoreGlobalScope: true
  })
  const { msg: msgSignIn } = useIntl({
    scope: 'equipzilla.api.useSignIn',
    ignoreGlobalScope: true
  })
  const [signin, { loading: signingIn }] = useSignIn()
  const {
    notify,
    statusTypes: { DANGER, SUCCESS, LOADING }
  } = useNotifications()
  const { notifyError } = useErrorNotification()

  const location = useLocation()
  const Analytics = useAnalytics()
  const trackAuthModal = ({ action }) => {
    if (Analytics.enabled) {
      Analytics.event({
        category: 'Form',
        label: location.pathname,
        action
      })
    }
  }

  const toggleMode = useCallback(() => {
    setMode(mode === 'signin' ? 'signup' : 'signin')
    scrollToModalTop()
  }, [mode, setMode])

  const setInitOptions = useCallback(
    ({ accessToken, refreshToken, refreshExpiresIn }) => {
      authContext
        .setInitOptions({
          refreshExpiresIn: refreshExpiresIn,
          token: accessToken,
          refreshToken: refreshToken,
          checkLoginIframe: false
        })
        .catch((error) => {
          notify({
            id: 'SIGNIN',
            message: _.get(error, 'data.message'),
            title: _.get(error, 'message'),
            status: DANGER
          })
        })
    },
    [DANGER, authContext, notify]
  )

  const notifySignIn = useCallback(
    (notification) => {
      notify({
        id: 'SIGNIN',
        title: msgSignIn({ id: notification.status }),
        ...notification
      })
    },
    [notify, msgSignIn]
  )

  const handleSignIn = useCallback(
    (params) => {
      notifySignIn({ status: LOADING })
      signin({ variables: { ...params } })
        .then(({ data: { signin } }) => {
          setInitOptions(signin)
          notifySignIn({ status: SUCCESS })
        })
        .catch((error) => {
          notifyError({
            id: 'SIGNIN',
            title: msgSignIn({ id: 'error' }),
            error
          })
        })
    },
    [
      LOADING,
      SUCCESS,
      msgSignIn,
      notifyError,
      notifySignIn,
      setInitOptions,
      signin
    ]
  )

  const disabled = initializing || signingIn
  let content = null
  if (mode === 'signup') {
    content = (
      <div className="AuthWrapper">
        <Block
          title={msg({ id: 'signUpTitle' })}
          variant="clear"
          className="SignUp"
        >
          <FeatheryForm formId="Contact" />
        </Block>
        <Block variant="clear" className="SignInLink">
          <p>
            {msg({ id: 'returnToSignIn' })}{' '}
            <button
              className="Link"
              onClick={() => {
                trackAuthModal({
                  action: 'Switch to SignIn'
                })
                toggleMode()
              }}
            >
              {msg({ id: 'goToSignIn' })}
            </button>
          </p>
        </Block>
      </div>
    )
  } else {
    content = (
      <div className="AuthWrapper">
        <Block
          title={msg({ id: 'signInTitle' })}
          variant="clear"
          className="SignIn"
        >
          <SignInForm
            onSubmit={(params) => {
              trackAuthModal({
                action: 'Click on SignIn Button'
              })
              handleSignIn(params)
            }}
            disabled={disabled}
          />
          <a
            className="ForgotPassword Link"
            href={`${recoveryUrl}&redirect_uri=${window.location.origin}`}
            disabled={disabled}
          >
            {msg({ id: 'forgotYourPassword' })}
          </a>
        </Block>
        <Block variant="clear" className="SignUpLink">
          <p>
            {msg({ id: 'createAnAccount' })}{' '}
            <button
              className="Link"
              onClick={() => {
                trackAuthModal({
                  action: 'Switch to SignUp'
                })
                toggleMode()
              }}
            >
              {msg({ id: 'goToSignUp' })}
            </button>
          </p>
        </Block>
      </div>
    )
  }
  return (
    <Modal
      className="AuthModal"
      name={'AuthenticationModal'}
      body={content}
      {...props}
    />
  )
}

AuthModal.displayName = 'AuthModal'

export default AuthModal
