import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@trileuco/triskel-react-ui/components/ui'
import { useModal } from '@trileuco/triskel-react-ui/components/ui/Modal'

import FeatheryRawModal from './FeatheryRawModal'

const FeatheryModalCloseButton = ({ close }) => (
  <button className="FeatheryModalCloseButton" onClick={close} />
)

FeatheryModalCloseButton.propTypes = {
  close: PropTypes.func
}

const FeatheryModal = ({ event, buttonClassName, buttonText, ...props }) => {
  const { isOpen, open, close } = useModal(props.initialOpen || false)

  const handleOpen = useCallback(() => {
    open()
    if (window.dataLayer && event) {
      window.dataLayer.push(event)
    }
  }, [event, open])
  return (
    <>
      <FeatheryRawModal isOpen={isOpen} onClose={close} {...props} />
      <Button
        text={buttonText}
        onClick={handleOpen}
        className={buttonClassName}
        {...props}
      />
    </>
  )
}

FeatheryModal.propTypes = {
  formId: PropTypes.string.isRequired,
  hiddenFields: PropTypes.object,
  buttonText: PropTypes.string.isRequired,
  buttonClassName: PropTypes.string,
  title: PropTypes.string,
  initialOpen: PropTypes.bool,
  event: PropTypes.object
}

FeatheryModal.displayName = 'FeatheryModal'

export default FeatheryModal
