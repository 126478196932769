import { useNotifications } from '@trileuco/triskel-react-ui/components/ui/notifications'

const useErrorNotification = () => {
  const {
    notify,
    statusTypes: { DANGER }
  } = useNotifications()

  const notifyError = ({ id, title, error }) =>
    notify({
      id,
      message: error.graphQLErrors.map((x) => x.message),
      title,
      status: DANGER
    })
  return {
    notifyError
  }
}

export default useErrorNotification
