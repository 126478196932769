import React from 'react'
import { FallingLines } from 'react-loader-spinner'

const Spinner = () => {
  return (
    <div>
      <FallingLines
        height="200"
        width="200"
        radius="9"
        color="#0a8080"
        ariaLabel="loading"
      />
    </div>
  )
}
export default Spinner
