import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { loader } from 'graphql.macro'
import _ from 'lodash'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'
import notFoundImage from 'assets/img/corporative/equipzilla/404.svg'
import { useApolloClient } from '@apollo/react-hooks'
import Button from '@trileuco/triskel-react-ui/components/ui/Button'
import * as Sentry from '@sentry/react'

const notifyWebError = loader(
  'modules/graphql/mutations/notifyWebError.graphql'
)

const InternalError = ({ error }) => {
  const { classNames } = useClassNames({
    alias: 'ErrorPage'
  })
  const client = useApolloClient()

  useEffect(() => {
    const {
      appCodeName,
      appName,
      appVersion,
      platform,
      userAgent,
      language,
      languages
    } = navigator

    const payload = {
      message: error.message,
      href: document.location.href,
      referrer: document.referrer,
      navigator: {
        appCodeName,
        appName,
        appVersion,
        platform,
        userAgent,
        language,
        languages
      },
      stack: _.get(error, 'stack')
    }

    Sentry.captureException(error)
    client
      .mutate({
        mutation: notifyWebError,
        variables: {
          payload: payload
        }
      })
      .then((response) => console.log(response.data))
      .catch((err) => console.error(err))

    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'error-web-client',
        eventLabel: _.omit(payload, 'stack')
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classNames()}>
      <div>
        <div className={classNames('image')}>
          <img src={notFoundImage} alt="" />
        </div>
        <div className={classNames('content')}>
          <h1>Ups! algo ha ido mal</h1>
          <p>{error && error.message}</p>
          <Button text="Ir a categorías" linkTo="/" />
        </div>
      </div>
    </div>
  )
}

InternalError.propTypes = {
  error: PropTypes.object
}

InternalError.displayName = 'InternalError'

export default InternalError
