import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { init, Form, setValues } from '@feathery/react'
import { useTenantConfig } from 'modules/Provider'

import Spinner from './Spinner'

const FeatheryForm = ({ formId, hiddenFields, onSubmit }) => {
  const formRef = useRef()
  const [isInitialized, setIsInitialized] = useState(false)
  const [initializationError, setInitializationError] = useState(false)
  const [seed, setSeed] = useState(1)

  const {
    feathery: {
      keys: { productionKey },
      forms: { leads }
    }
  } = useTenantConfig()

  useEffect(() => {
    const initializeFeathery = async () => {
      setIsInitialized(false)
      try {
        await init(productionKey) // Initialize Feathery
        setIsInitialized(true)
      } catch (error) {
        console.error('Feathery initialization error:', error)
        setInitializationError(true)
      }
    }
    initializeFeathery()
    setSeed(Math.random())

    return () => {
      // Clean up Feathery resources if needed
    }
  }, [productionKey, hiddenFields])

  const handleSubmit = () => {
    try {
      if (leads === formId) {
        setValues({ assetType: hiddenFields.assetType })
      }
    } catch (e) {
      console.log(e)
    }
  }

  if (initializationError) {
    return (
      <p>
        Oops! Surgió un problema al cargar el formulario. Intentelo de nuevo mas
        tarde.
      </p>
    )
  }

  if (!isInitialized) {
    return <p>Cargando formulario...</p>
  }

  return (
    <div key={{ seed }}>
      <Form
        ref={formRef}
        formName={formId}
        initialValues={hiddenFields}
        onSubmit={handleSubmit}
        initialLoader={{
          show: true,
          loader: <Spinner />
        }}
      />
    </div>
  )
}

FeatheryForm.propTypes = {
  formId: PropTypes.string.isRequired,
  hiddenFields: PropTypes.object,
  onSubmit: PropTypes.func
}

export default FeatheryForm
