import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const Body = (props) => {
  return <Fragment>{props.children}</Fragment>
}

Body.propTypes = {
  children: PropTypes.node
}

Body.displayName = 'Body'

export default Body
