import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React from 'react'
import moment from 'moment'
import TagManager from 'react-gtm-module'
import { hotjar } from 'react-hotjar'
import TopBarProgress from 'react-topbar-progress-indicator'
import { render } from '@trileuco/triskel-react-ui'
import Provider from 'modules/Provider'
import AuthComponent from 'components/auth/AuthComponent'
import Body from 'components/layout/Body/Body'
import { getTenantConfig } from 'tenants'
import * as serviceWorker from 'serviceWorker'
import InternalError from 'components/error/InternalError'
import 'styles/theme.equipzilla.scss'

TopBarProgress.config({
  barColors: {
    '0': '#f3533d',
    '1.0': '#f3533d'
  },
  shadowBlur: 1
})

/**
 * Do feature detection, to figure out which polyfills needs to be imported.
 **/
async function loadPolyfills() {
  if (typeof window.IntersectionObserver === 'undefined') {
    await import('intersection-observer')
  }
}

const appModulesImport = (modules) =>
  Promise.all(
    modules.map((moduleName) =>
      import(/* webpackMode: "lazy" */ `modules/${moduleName}/index.js`).then(
        (module) => module.default
      )
    )
  )

getTenantConfig().then(async (tenantConfig) => {
  moment.locale(tenantConfig.intl.defaultLocale)
  // moment.tz.setDefault(tenantConfig.intl.timeZone)
  if (tenantConfig.googleTagManager) {
    TagManager.initialize(tenantConfig.googleTagManager)
  }
  if (tenantConfig.hotjar) {
    hotjar.initialize(tenantConfig.hotjar.hjid, tenantConfig.hotjar.hjsv)
  }
  await loadPolyfills()
  appModulesImport(tenantConfig.modules).then((modules) => {
    render({
      ...tenantConfig,
      rootId: 'root',
      Layout: Body,
      // eslint-disable-next-line react/display-name
      CustomProvider: (props) => (
        <Provider {...props} tenantConfig={tenantConfig} />
      ),
      ErrorHandler: InternalError,
      notifications: {
        position: 'right',
        align: 'start'
      },
      authentication: {
        AuthComponent: AuthComponent,
        ...tenantConfig.authentication
      },
      modules: [...modules]
    })
  })
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWAra

serviceWorker.unregister()
