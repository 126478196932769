import { useMutation } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'
const signinMutation = loader('modules/graphql/mutations/signin.graphql')

const useSignIn = (options) => {
  return useMutation(signinMutation, {
    ...options,
    onError(error) {
      throw error
    }
  })
}

export default useSignIn
