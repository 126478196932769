import React from 'react'
import PropTypes from 'prop-types'
import { SubmitButton } from '@trileuco/triskel-react-ui/components/ui/Form'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import {
  Form,
  FieldSet,
  TextField
} from '@trileuco/triskel-react-ui/components/ui'

const SignInForm = ({ onSubmit, disabled }) => {
  const { msg } = useIntl({
    scope: 'equipzilla.signInForm',
    ignoreGlobalScope: true
  })
  return (
    <Form onSubmit={onSubmit} disabled={disabled}>
      <FieldSet direction="column">
        <TextField
          variant="outline"
          disabled={disabled}
          label={msg({ id: 'name' })}
          field="username"
          rules={['required']}
        />
        <TextField
          variant="outline"
          disabled={disabled}
          label={msg({ id: 'password' })}
          field="password"
          type="password"
          rules={['required']}
        />
        <SubmitButton text={msg({ id: 'submit' })} disabled={disabled} />
      </FieldSet>
    </Form>
  )
}

SignInForm.propTypes = {
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool
}

SignInForm.displayName = 'SignInForm'

export default SignInForm
